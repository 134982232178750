<template>
    <b-sidebar id="sidebar-createTransaction" right shadow bg-variant="white" backdrop>
        <template #default="{hide}">
            <b-card :title=createTransactionTitle>
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group label="Payment Mode *">
                                <vSelect v-model="paymentModeType" :options="paymentModeTypeOptions"
                                    placeholder="Select Payment Mode"></vSelect>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Payment Description">
                                <b-form-input v-model="paymentDescription"
                                    placeholder="Select Payment Description"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Amount *">
                                <cleave v-model="amount" class="form-control" :raw="true" :options="options.number"
                                    placeholder="Enter Amount" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Description">
                                <b-form-textarea v-model="transactionDescription" rows="2"
                                    placeholder="Enter Description"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-center">
                            <b-button variant="primary" v-on:click="addWithdrawRefundAction" @click="hide">{{
                                addWithdrawRefund
                            }}</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </template>
    </b-sidebar>
</template>
<script>
import {
    BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BButton, BSidebar
} from 'bootstrap-vue';
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import api from '@/store/api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BButton, vSelect, Cleave, BSidebar
    },
    data() {
        return {
            amount: 0,
            options: {
                number: {
                    numeral: true,
                },
            },
            transactionDescription: '',
            paymentModeTypeOptions: [],
            paymentModeType: '',
            paymentDescription: '',
            transactionType:'',
            defaultConfiguration:JSON.parse(localStorage.getItem('defaultConfiguration'))

        }
    },
    methods: {
        addWithdrawRefundAction() {
            let self = this;
            if (self.paymentModeType !='' && self.amount > 0) {
                if(self.addWithdrawRefund=='Add'){
                    self.transactionType='CREDIT'
                }else{
                    self.transactionType='DEBIT'
                }
                var axios = require('axios');
                var data = JSON.stringify(
                    {
                        "amount": self.amount,
                        "currency": self.defaultConfiguration.currency,
                        "paymentDescription": self.paymentDescription,
                        "paymentMode": self.paymentModeType,
                        "storeId": self.$store.state.storeId,
                        "transactionDescription": self.transactionDescription,
                        "transactionType": self.transactionType,
                        "walletId": self.walletId
                    });
                axios(api.getApi('Post', '/finance/accounts/createTransaction', data))
                    .then(function (response) {
                        //console.log(JSON.stringify(response.data));
                        self.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Transaction Successfully Done!`,
                                icon: 'SaveIcon',
                                variant: 'success',
                            },
                        })

                        if(self.customerId!='undefined'){
                            self.getCustomerDetailsWalletOrder(self.customerId);
                        }
                        
                        if(self.searchBoxValue!=null){
                            self.getCustomerList(self.searchBoxValue);
                        }

                        self.resetForm();
                        
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            } else {
                this.$swal({
                    title: '',
                    text: "Please Enter Payment Type And Amount.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }
        },
        getPaymentModeType() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/paymentModeTypes', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    const array = response.data;

                    //Remove E_WALLET
                    const index = array.indexOf('E_WALLET');
                    if (index > -1) {
                        array.splice(index, 1);
                    }

                    self.paymentModeTypeOptions = array;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        resetForm(){
            this.paymentModeType='';
            this.paymentDescription='';
            this.amount='';
            this.transactionDescription='';
        }

    },
    props: {
        addWithdrawRefund: String,
        createTransactionTitle: String,
        customerId: Number,
        walletId: Number,
        getCustomerDetailsWalletOrder:Function,
        searchBoxValue:String,
        getCustomerList:Function
    },
    created() {
        let self = this;
        self.getPaymentModeType();
    },
    mutations() {

    }

}
</script>