import store from '@/store/index'
export default {
    getApi(methodType, url, jsonData) {
        var config = {
            method: methodType,
            url: store.state.baseUrl + url,
            headers: {
                'Authorization': store.state.token,
                'Content-Type': 'application/json'
            },
            data: jsonData
        };
        return config
    }
}